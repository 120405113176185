import { Box, Grid, Typography } from '@mui/material';
import { Image } from 'mui-image';
import GuestLayout from '../../components/layout/GuestLayout';
import RegistrationClosed from '../../components/registration/RegistrationClosed';
import banner from '../../images/logo/Jonfest-Banner-2023-640x160.jpg';

export default function FoodRegistrationFull() {
  return (
    <GuestLayout>
      <Grid
        container
        rowSpacing={0}
        sx={{ width: '100%', maxWidth: '640px' }}
        columns={{ xs: 12 }}
        justifyContent="flex-start"
      >
        <Grid item xs={12} alignContent={'center'}>
          <Box
            sx={{ maxWidth: '450px', minWidth: '300px' }}
            textAlign="center"
            p={1}
          >
            <Box m={0.5} pb={1}>
              <Image src={banner} duration={0}></Image>
            </Box>
            <Typography variant="h3">
              Sorry but your order has already been placed. Please contact
              Jonfest Crew if you have any issues.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </GuestLayout>
  );
}
