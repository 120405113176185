import Grid from "@mui/system/Unstable_Grid";
import LoginWidget from "../components/auth/LoginWidget";
import { Image } from 'mui-image';

import logo from '../images/logo/JonFest.png';

export default function Login() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '95vh' }}
    >
      <Grid xs={12} maxWidth='360px'>
        <Image src={logo}></Image>
      </Grid>
      <Grid xs={12}>
        <LoginWidget></LoginWidget> 
      </Grid>
    </Grid>
  );
}
