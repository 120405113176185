import AddGuest from './pages/guest/AddGuest';
import Front from './pages/Front';
import SignIn from './pages/Login';

import Logout from './pages/Logout';
import { routeEntry } from './util/common';
import Login from './pages/Login';
import GuestsConfirm from './pages/guest/GuestsConfirm';
import Bar from './pages/Bar/Bar';
import FoodConfirm from './pages/food/FoodConfirm';
import FoodSummary from './pages/food/FoodSummary';
import Register from './pages/jonfest/Register';
import RegisterDone from './pages/jonfest/RegisterDone';
import RegisterFull from './pages/jonfest/RegisterFull';
import Admin from './pages/Admin';
import GuestsOverview from './pages/guest/GuestsOverview';
import RegisterClosed from './pages/jonfest/RegisterClosed';
import RegisterNotOpen from './pages/jonfest/RegisterNotOpen';
import Guest from './pages/guest/Guest';
import FoodRegistrationClosed from './pages/jonfest/FoodRegistrationClosed';
import FoodRegistration from './pages/jonfest/FoodRegistration';
import FoodRegistrationDone from './components/registration/FoodRegistrationDone';
import FoodRegistrationFull from './pages/jonfest/FoodRegistrationFull';
import FoodCheckOff from './pages/food/FoodCheckOff';

// Navigation Routes

//export const routes = [{ nav: 'mydatasheet', element: MyDataSheet }];
export const routes: Array<routeEntry> = [
  new routeEntry('guestslist', GuestsOverview),
  new routeEntry('guest/:id', Guest),
  new routeEntry('guestsadd', AddGuest),
  new routeEntry('guestsconfirm', GuestsConfirm),
  new routeEntry('bar', Bar),
  new routeEntry('food', FoodCheckOff),
  new routeEntry('foodconfirm', FoodConfirm),
  new routeEntry('foodsummary', FoodSummary),
  new routeEntry('admin', Admin),
];

export const unauthedRoutes: Array<routeEntry> = [
  new routeEntry('login', Login),
  new routeEntry('signin-oidc', SignIn),
  new routeEntry('logout', Logout),
  new routeEntry('register', Register),
  new routeEntry('registrationDone/:code', RegisterDone),
  new routeEntry('registrationFull', RegisterFull),
  new routeEntry('registrationClosed', RegisterClosed),
  new routeEntry('registrationNotOpen', RegisterNotOpen),
  new routeEntry('foodRegistration/:code', FoodRegistration),
  new routeEntry('foodRegistrationClosed', FoodRegistrationClosed),
  new routeEntry('foodRegistrationFull', FoodRegistrationFull),
  new routeEntry('', Front),
];
