import { AxiosRequestHeaders } from "axios";

export default function authHeader() : AxiosRequestHeaders {
    const token = localStorage.getItem('c7token') || '';
  
    if (token) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return {};
    }
  }