import React from 'react';
import { useEffect, useRef } from 'react';

export class Common {
  public static getObject = (object: any, key: string): any => {
    if (Array.isArray(object)) {
      for (const obj of object) {
        const result = this.getObject(obj, key);
        if (result) {
          return obj;
        }
      }
    } else {
      if (object.hasOwnProperty(key)) {
        return object;
      }

      for (const k of Object.keys(object)) {
        if (typeof object[k] === 'object') {
          const o = this.getObject(object[k], key);
          if (o !== null && typeof o !== 'undefined') return o;
        }
      }

      return null;
    }
  };

  public static usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  public static useEffectDebugger = (
    effectHook: any,
    dependencies: any,
    dependencyNames = []
  ) => {
    const previousDeps = Common.usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce(
      (accum: any, dependency: any, index: number) => {
        if (dependency !== previousDeps[index]) {
          const keyName = dependencyNames[index] || index;
          return {
            ...accum,
            [keyName]: {
              before: previousDeps[index],
              after: dependency,
            },
          };
        }

        return accum;
      },
      {}
    );

    if (Object.keys(changedDeps).length) {
      console.log('[use-effect-debugger] ', changedDeps);
    }

    useEffect(effectHook, dependencies);
  };
}

export class routeEntry {
  nav: string = '';
  element = (): JSX.Element => {
    return React.createElement(React.Component);
  };

  constructor(_nav: string, _element: any) {
    this.nav = _nav;
    this.element = _element;
  }
}

export function getNavigationLink(page: any): string {
  if (page.routes.length > 0) {
    return page.nav + '/' + page.routes[0].nav;
  } else return page.nav;
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
