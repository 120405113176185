import { Box } from '@mui/material';
import React from 'react';
import TabMenu from '../menu/TabMenu';

interface Props {
  children: React.ReactNode;
}

const PageLayout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Box className="page-content">
        <TabMenu
        menuItems={[          
          { label: "Gäst lista", to: '/guestslist' },
          { label: "Bar", to: '/bar' },
          { label: "Mat", to: '/food' },
          { label: "Lägg till gäst", to: '/guestsadd' },
          { label: "Bekräfta gäster", to: '/guestsconfirm' },
          { label: "Bekräfta mat", to: '/foodconfirm' },          
          { label: "Matsammanställning", to: '/foodsummary' },
        ]}
      />
      <Box>{props.children}</Box>
    </Box>
  );
};

export default PageLayout;
