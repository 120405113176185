import ReactDOM from 'react-dom/client';
import App from './App';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { routes, unauthedRoutes as unauthedRoutes } from './routes';

import './styles/jfmain.css';
import PrivateRoute from './components/auth/PrivateRoute';
import AppLayout from './components/layout/AppLayout';
import Front from './pages/Front';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <StrictMode>
  <BrowserRouter>
    <Routes>      
      <Route path="/" element={<App />}>       
        {/* Other protected routes using AppLayout */}
        {routes.map((page, index) => (
          <Route
            path={page.nav}
            element={<PrivateRoute><AppLayout>{page.element()}</AppLayout></PrivateRoute>} 
            key={page.nav}
          ></Route>
        ))}

        {/* Unprotected routes */}
        {unauthedRoutes.map((page, index) => (
          <Route
            path={page.nav}
            element={page.element()}
            key={page.nav}
          ></Route>
        ))}

        {/* Fallback routes */}
        <Route path="/" element={<Front />} />
        <Route path="*" element={<Front />} />
      </Route>
    </Routes>
  </BrowserRouter>
  // </StrictMode>
);
