import axios from "axios";
import { c7AuthUser } from "../models/auth/c7AuthUser";
import { c7LoginModel } from "../models/auth/c7LoginModel";

const API_URL = process.env.REACT_APP_API;

class AuthService {
  login = async (params: c7LoginModel): Promise<c7AuthUser> => {
    return new Promise((resolve, reject) => {
      axios
      .post(API_URL + "admin/login", params)
      .then((response) => {
        if (response.data.isAuthenticated === true) {
          this.setLoggedIn(response.data);
        } 
        resolve(response.data);        
      })

      .catch(function (error) {
        console.log("Error!");
        console.log(error);
        reject({ isAuthenticated: false, error: error } as c7AuthUser);

      });

    });
  }

  isAuthenticated() {
    const token = localStorage.getItem("c7token");
    return token ? true : false;
  }

  setLoggedIn = (user: c7AuthUser) => {
    localStorage.setItem("c7token", user.token);
    localStorage.setItem("c7username", user.username);
    localStorage.setItem("c7userId", user.userId);
  }

  getLoggedIn = () : c7AuthUser => {
    if(this.isAuthenticated())
    {
        return { username: localStorage.getItem("c7username") || '', userId: localStorage.getItem("c7userId") || '', error: '', token: localStorage.getItem("c7token") || '', isAuthenticated: true} as c7AuthUser;
    }
    else
    {
      return  { username: '', userId: '', error: '', token: '', isAuthenticated: false} as c7AuthUser;
    }
  }

  logout() {
    localStorage.removeItem("c7token");
    localStorage.removeItem("c7username");
    localStorage.removeItem("c7userId");
  }
}

export default new AuthService();