import { Box, CircularProgress, Grid, Typography } from "@mui/material";

export default function Loading() {
    return (
        <Box>
            <Box display="flex" justifyContent="center" my={6}>
                <Box>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12} textAlign={"center"}>
                        <CircularProgress />
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Typography>Loading...</Typography>
                    </Grid>
                </Grid>
                </Box>
            </Box>
        </Box>
    )
}