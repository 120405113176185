import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Suspense } from 'react';

import { Outlet } from 'react-router-dom';

function App() {
  // console.log(process.env.REACT_APP_AXIOS_BASE_URL);
  // console.log(process.env.REACT_APP_OAUTH_AUTHORITY);
  // console.log(process.env.REACT_APP_OAUTH_REDIRECT_URI);
  // console.log(process.env.REACT_APP_OAUTH_CLIENT_ID);
  // console.log(process.env.NODE_ENV);
  // console.log(process.env);

  

  return (
    <Suspense fallback="...is loading">        
        <Provider store={store}>
            <Outlet />
        </Provider>        
    </Suspense>
  );
}

export default App;
