import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { checkboxClasses } from '@mui/material/Checkbox';

const mainAdminColor = '#000';
const highlightAdminColor = '#f11';
const mainGuestColor = '#000';
const mainLightColor = '#fff';
const mainMediumColor = '#aaa';

export const adminTheme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: 'arial',
    body1: {
      color: mainAdminColor,
    },
    body2: {
      color: mainLightColor,
      fontSize: '1.2rem',
    },
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.1rem',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      dark: mainAdminColor,
      main: mainAdminColor,
    },
    secondary: {
      main: mainLightColor,
    },
    background: {
      default: '#000000',
      paper: mainAdminColor,
    },
    text: {
      primary: mainAdminColor,
      secondary: '#c8e4f2d0',
      disabled: '#c8e4f280',
    },
    info: {
      main: '#29f6d0',
    },
    divider: mainAdminColor,
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#000',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid ' + mainAdminColor,
          backgroundColor: '#000',
          color: '#fff',
        },
        colorPrimary: {
          main: mainAdminColor,
          backgroundColor: '#000',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 52,
          minHeight: 52,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#aaaaaa',
          },
        },
        contained: {
          borderRadius: 8,
          borderColor: '#ffff17',
          border: '1px solid',
          color: '#fff',
        },
        outlined: {
          borderRadius: 8,
          borderColor: mainAdminColor,
          color: mainLightColor,
          backgroundColor: highlightAdminColor,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: mainAdminColor,
          fontSize: '0.75rem',
          padding: '8px 12px',
          minHeight: '32px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '32px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: mainAdminColor,
        },
        input: {
          paddingLeft: '8px',
          fontSize: '0.9rem',
        },
      },
    },
    // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: mainAdminColor,
          fontSize: '0.825rem',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          color: mainAdminColor,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'red',
          [`&.${checkboxClasses.checked}`]: {
            color: 'red',
          },
        },
        colorPrimary: {
          color: 'red',
          [`&.${checkboxClasses.checked}`]: {
            color: 'red',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:focus': {
            backgroundColor: mainMediumColor,
          },
          '&:hover': {
            backgroundColor: mainMediumColor,
          },
          backgroundColor: mainLightColor,
          '&.Mui-selected': {
            backgroundColor: mainAdminColor,
            color: mainLightColor,
          },
        },
      },
    },
  },
});

export const loginTheme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: 'arial',
    body1: {
      color: mainLightColor,
    },
    body2: {
      color: mainLightColor,
      fontSize: '1.2rem',
    },
    h1: {
      color: mainLightColor,
      fontSize: '2rem',
    },
    h2: {
      color: mainLightColor,
      fontSize: '1.5rem',
    },
    h3: {
      color: mainLightColor,
      fontSize: '1.1rem',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      dark: mainLightColor,
      main: mainLightColor,
    },
    secondary: {
      main: mainLightColor,
    },
    background: {
      default: '#000000',
      paper: '#000000',
    },
    text: {
      primary: mainLightColor,
      secondary: '#c8e4f2d0',
      disabled: '#c8e4f280',
    },
    info: {
      main: '#29f6d0',
    },
    divider: mainLightColor,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#aaaaaa',
          },
        },
        contained: {
          borderRadius: 8,
          borderColor: '#ffff17',
          border: '1px solid',
          color: '#fff',
        },
        outlined: {
          borderRadius: 8,
          borderColor: mainLightColor,
          color: mainLightColor,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 0,
          color: mainLightColor,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: mainLightColor,
        },
        input: {
          paddingLeft: '8px',
          fontSize: '0.9rem',
        },
      },
    },
  },
});

export const guestTheme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: 'arial',
    body1: {
      color: mainLightColor,
    },
    body2: {
      color: mainLightColor,
      fontSize: '1.2rem',
    },
    h1: {
      color: mainLightColor,
      fontSize: '2rem',
    },
    h2: {
      color: mainLightColor,
      fontSize: '1.5rem',
    },
    h3: {
      color: mainLightColor,
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      dark: mainLightColor,
      main: mainLightColor,
    },
    secondary: {
      main: mainLightColor,
    },
    background: {
      default: mainGuestColor,
      paper: mainGuestColor,
    },
    text: {
      primary: mainLightColor,
      secondary: '#c8e4f2d0',
      disabled: '#c8e4f280',
    },
    info: {
      main: '#29f6d0',
    },
    divider: mainLightColor,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#aaaaaa',
          },
        },
        contained: {
          borderRadius: 8,
          borderColor: '#ffff17',
          border: '1px solid',
          color: '#fff',
        },
        outlined: {
          borderRadius: 8,
          borderColor: mainLightColor,
          color: mainLightColor,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 0,
          color: mainLightColor,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            borderColor: mainLightColor,
          },
          borderColor: mainLightColor,
        },
        notchedOutline: {
          borderColor: mainLightColor,
        },
        input: {
          paddingLeft: '8px',
          fontSize: '1.0rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          borderColor: mainLightColor,
          '&.Mui-focused': {
            color: mainLightColor,
            borderColor: mainLightColor,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: mainLightColor,
          },
        },
      },
    },
  },
});
