import { Grid, Typography } from '@mui/material';
import GuestOverviewList from '../../components/admin/GuestOverviewList';
import PageLayout from '../../components/layout/PageLayout';

export default function GuestsOverview() {
  return (
    <PageLayout>
      <Grid item xs={12}>
        <GuestOverviewList></GuestOverviewList>
      </Grid>
    </PageLayout>
  );
}
