import { Box, Typography } from "@mui/material";
import { Image } from 'mui-image';
import banner from '../../images/logo/Jonfest-Banner-2023-640x160.jpg';

export default function RegistrationNotOpen() {
    return (
        <Box sx={{ maxWidth: '450px', minWidth: '300px' }} textAlign='center' p={1}>
            <Box m={0.5} pb={1}>
                <Image src={banner} duration={0} ></Image>
            </Box>
            <Typography variant='h3'>Registration for Jonfest V - 2023 is not open yet!</Typography>
            <Typography>Estimated time for opening is: April 2</Typography>
        </Box>

    )
}