import { Paper, ThemeProvider } from '@mui/material';
import TopBar from '../menu/TopBar';
import {adminTheme} from '../../Theme';

import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

interface IAppLayoutProps {
  children: any;
}

const AppLayout = (props: IAppLayoutProps) => (
  <ThemeProvider theme={adminTheme}>
  <main>
    <Box sx={{ display: 'flex', width: '100%' }}>
      <TopBar />
      <Box marginTop={4} sx={{ display: 'flex', width: '100%' }}>
        {props.children}
      </Box>
      <Paper
        className="bottomBarPaper"
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
      </Paper></Box>

  </main>
  </ThemeProvider>
);

export default AppLayout;
