import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { JFGuestDTO } from '../../models/guest';
import adminService from '../../services/admin.services';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from '@mui/x-data-grid';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { JFPaidInfoDTO } from '../../models/paidInfo';
import Loading from '../widgets/Loading';
import { GuestTypeEnum } from '../../models/enums';
import { Link, useNavigate } from 'react-router-dom';

export default function GuestConfirmList() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPostingPay, setIsPostingPay] = useState<number | undefined>();
  const [isLoadingError, setLoadingError] = useState(false);
  const [guests, setGuests] = useState<JFGuestDTO[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    adminService
      .getGuests()
      .then((response) => {
        setGuests(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setLoadingError(true);
      });
  }, []);

  const handleOnCellClick = (params: GridCellParams<JFGuestDTO>) => {
    if (params.field === 'name') {
      navigate('/guest/' + params.row.id.toString());
    }
    if (params.field === 'paid') {
      setIsPostingPay(params.row.id);
      adminService
        .postPaid({
          guestId: params.row.id,
          sendConfirm: true,
          value: !params.row.paid,
        } as JFPaidInfoDTO)
        .then((resp) => {
          if (resp.data !== '') {
            console.log(resp.data);
            if (resp.data > 0) {
              params.row.ticketNumber = resp.data;
            }

            params.row.paid = !params.row.paid;
          }
          setIsPostingPay(undefined);
        })
        .catch((error) => {
          alert('Unable to set Paid on guest! ' + error.message);
        });
    }
    if (params.field === 'confirmed') {
      setIsPostingPay(params.row.id);
      adminService
        .postConfirmed({
          guestId: params.row.id,
          sendConfirm: true,
          value: !params.row.confirmed,
        } as JFPaidInfoDTO)
        .then((resp) => {
          params.row.confirmed = !params.row.confirmed;
          setIsPostingPay(undefined);
        })
        .catch((error) => {
          alert('Unable to Confirm guest! ' + error.message);
        });
    }
  };

  const columns = useMemo<GridColDef<JFGuestDTO>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Namn',
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <>{params.value}</>;
        },
      },
      { field: 'email', headerName: 'E-post', flex: 1 },
      {
        field: 'guid',
        headerName: 'Kod',
        flex: 0.3,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <>{params.value?.substring(0, 6)}</>;
        },
      },
      { field: 'ticketNumber', headerName: 'Biljett', flex: 0.3 },
      {
        field: 'paid',
        headerName: 'Betalt',
        flex: 0.35,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          if (isPostingPay == params.row.id) {
            return (
              <Box ml={0.5} pt={0.5}>
                <CircularProgress size={16}></CircularProgress>
              </Box>
            );
          } else if (params.value == true) {
            return <CheckBoxOutlinedIcon color={'success'} />;
          } else {
            return <CheckBoxOutlineBlankIcon />;
          }
        },
      },
      {
        field: 'confirmed',
        headerName: 'Bokad',
        flex: 0.35,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          if (isPostingPay == params.row.id) {
            return (
              <Box ml={0.5} pt={0.5}>
                <CircularProgress size={16}></CircularProgress>
              </Box>
            );
          } else if (params.value == true) {
            return <CheckBoxOutlinedIcon color={'success'} />;
          } else {
            return <CheckBoxOutlineBlankIcon />;
          }
        },
      },
    ],
    [isPostingPay]
  );

  return (
    <Grid item xs={12}>
      {isLoadingError ? (
        <>
          <Typography>
            Error: Unable to load Jonfest guests at this point!
          </Typography>
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <Loading></Loading>
            </>
          ) : (
            <>
              <Box>
                <Typography>
                  <b>Gäster:</b> Betalda:{' '}
                  {guests.reduce((n, guest) => {
                    return n + (guest.paid == true ? 1 : 0);
                  }, 0)}{' '}
                  - Bokade:{' '}
                  {guests.reduce((n, guest) => {
                    return (
                      n +
                      (guest.confirmed == true &&
                      guest.guestType == GuestTypeEnum.Normal
                        ? 1
                        : 0)
                    );
                  }, 0)}
                </Typography>
              </Box>
              <div style={{ height: 650, width: '100%' }}>
                <DataGrid
                  disableSelectionOnClick
                  headerHeight={32}
                  rowHeight={24}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  rows={guests}
                  columns={columns}
                  onCellClick={handleOnCellClick}
                  getRowClassName={(params) =>
                    `guest-list--${params.row.guestType}`
                  }
                />
              </div>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
