import { Grid, Typography } from '@mui/material';
import GuestFoodConfirmList from '../../components/admin/GuestFoodConfirmList';
import PageLayout from '../../components/layout/PageLayout';

export default function FoodConfirm() {
  return (
    <PageLayout>
      <Grid item xs={12}>
        <GuestFoodConfirmList></GuestFoodConfirmList>
      </Grid>
    </PageLayout>
  );
}
