import { Grid, Typography } from '@mui/material';
import PageLayout from '../../components/layout/PageLayout';

export default function FoodSummary() {
  return (
    <PageLayout>
      <Grid item xs={6}>
        <Typography>Matsammanställning</Typography>
      </Grid>
      <Grid item xs={6}></Grid>
    </PageLayout>
  );
}
