import { Box, CssBaseline, Grid, ThemeProvider } from '@mui/material';
import React from 'react';
import { guestTheme } from '../../Theme';

interface Props {
    children: React.ReactNode;
}

const PageLayout: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <ThemeProvider theme={guestTheme}>
            <CssBaseline></CssBaseline>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                marginBottom={2}
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={6}>
                    <Box className='guest'>{props.children}</Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default PageLayout;
