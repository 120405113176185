import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import adminService from '../../services/admin.services';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from '@mui/x-data-grid';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { JFPaidInfoDTO } from '../../models/paidInfo';
import Loading from '../widgets/Loading';
import { FoodStatusEnum, GuestTypeEnum } from '../../models/enums';
import { Link, useNavigate } from 'react-router-dom';
import { JFGuestFoodDTO, JFGuestFoodSlotCheckInDTO, JFGuestFoodSlotDTO } from '../../models/food';
import { JFFoodSlotDTO } from '../../models/foodRegistration';
import { Controller } from 'react-hook-form';

export default function GuestFoodCheckOffList() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPostingCheckOut, setIsPostingCheckOut] = useState<number | undefined>();
  const [isLoadingError, setLoadingError] = useState(false);
  const [guests, setGuests] = useState<JFGuestFoodSlotDTO[]>([]);
  const [foodSlot, setFoodSlot] = useState<number | undefined>();
  const [foodSlotName, setFoodSlotName] = useState<string>(' ');
  const [foodSlots, setFoodSlots] = useState<JFFoodSlotDTO[]>([]);

  useEffect(() => {
    adminService
      .getFoodSlots()
      .then((response) => {
        setFoodSlots(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setLoadingError(true);
      });
  }, []);

  useEffect(() => {
    if(foodSlot != undefined) {
    adminService
      .getGuestFoodList(foodSlot!)
      .then((response) => {
        console.log(response.data);
        setGuests(response.data);
      })
      .catch((err) => {
        setLoadingError(true);
      });
    }
  }, [foodSlot]);

  useEffect(() => {
    setFoodSlot(foodSlots.find(fs => fs.name == foodSlotName)?.foodSlot)
  }, [foodSlotName]);

  const handleOnCellClick = (params: GridCellParams<JFGuestFoodSlotDTO>) => {
    if (params.field === 'status') {
      setIsPostingCheckOut(params.row.id);
      adminService
        .postCheckInFood({
          id: params.row.id,
          value: params.row.status == FoodStatusEnum.Ordered ? FoodStatusEnum.CheckedOut : FoodStatusEnum.Ordered,
        } as JFGuestFoodSlotCheckInDTO)
        .then((resp) => {
          params.row.status = (params.row.status == FoodStatusEnum.Ordered) ? FoodStatusEnum.CheckedOut : FoodStatusEnum.Ordered;
          setIsPostingCheckOut(undefined);
        })
        .catch((error) => {
          alert('Unable to check in food! ' + error.message);
        });
    }
  };

  const columns = useMemo<GridColDef<JFGuestFoodSlotDTO>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Namn',
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <>{params.value}</>;
        },
      },
      {
        field: 'vegan',
        headerName: 'Vegan',
        flex: 0.3,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          if(params.value == true)
          {
            return <>Ja</>
          }
          else
            return <></>
        },
      },

      {
        field: 'status',
        headerName: 'Hämtat?',
        flex: 0.35,
        renderCell: (params: GridRenderCellParams<FoodStatusEnum>) => {
          if (isPostingCheckOut == params.row.id) {
            return (
              <Box ml={0.5} pt={0.5}>
                <CircularProgress size={16}></CircularProgress>
              </Box>
            );
          } else if (params.value == FoodStatusEnum.CheckedOut) {
            return <CheckBoxOutlinedIcon color={'success'} />;
          } else {
            return <CheckBoxOutlineBlankIcon />;
          }
        },
      },
    ],
    [isPostingCheckOut]
  );


  return (
    <Grid item xs={12}>
      {isLoadingError ? (
        <>
          <Typography>
            Error: Unable to load Jonfest guests at this point!
          </Typography>
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <Loading></Loading>
            </>
          ) : (
            <>
              <Box>
                <Typography>Mat</Typography>
              </Box>
              <Box>
              <Box margin={0.5} marginTop={1.0}>
                Serveringstillfälle<br />
                <select onChange={e => setFoodSlotName(e.target.value)} value={foodSlotName} defaultValue={' '}>
                        <option value=' ' disabled>Välj...</option>
                        {foodSlots.map(fs => 
                            { return <option key={fs.foodSlot} value={fs.name}>{fs.name}</option>; }
                          )}
                  </select>
              </Box>
              </Box>
              {guests.length > 0 ?
                <div style={{ height: 650, width: '100%' }}>
                  <DataGrid
                    disableSelectionOnClick
                    headerHeight={32}
                    rowHeight={24}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    rows={guests}
                    columns={columns}
                    onCellClick={handleOnCellClick}           
                    getRowId={(r) => r.guestGuid}       
                    getRowClassName={(params) =>
                      `guest-list--${params.row.guestType}`
                    }
  
                  />
                </div>
                : <></>}
            </>
          )}
        </>
      )}
    </Grid>
  );
}
