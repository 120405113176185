import { Grid } from "@mui/material";
import GuestLayout from "../../components/layout/GuestLayout";
import RegistrationNotOpen from "../../components/registration/RegistrationNotOpen";

export default function RegisterNotOpen() {
    return (
        <GuestLayout>
            <Grid
                container
                rowSpacing={0}
                sx={{ width: '100%', maxWidth: '640px' }}
                columns={{ xs: 12 }}
                justifyContent="flex-start"
            >
                <Grid item xs={12} alignContent={'center'}>
                   <RegistrationNotOpen />
                </Grid>
            </Grid>
        </GuestLayout>
    )
}