import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface TabItems {
  menuItems: LinkTabProps[];
}

class LinkTabProps {
  label: string = '';
  to: string = '';
} 

function LinkTab(props: LinkTabProps) {
  const navigate = useNavigate();

  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        navigate(props.to);
      }}
      {...props}
    />
  );
}
const TabMeny = (props: TabItems) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ maxWidth: { xs: 360, sm: 600, md: 1280 } }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {props.menuItems.map((i) => {
          return <LinkTab key={i.label} label={i.label} to={i.to} />;
        })}
      </Tabs>
    </Box>
  );
};

export default TabMeny;
