import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import navigationReducer from './slices/navigation';

export const store = configureStore({
  reducer: {
    user: userReducer,
    navigation: navigationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
