import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { c7AuthUser } from '../../models/auth/c7AuthUser';

const initialState: c7AuthUser = { username: '', userId: '', error: '', token: '', isAuthenticated: false};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<c7AuthUser>) => {
      return action.payload;
    },    
    logoutUser: (state) => {
      state =  { username: '', userId: '', error: '', token: '', isAuthenticated: false};
      return state;
    },
  },
});

export const { setUser, logoutUser } =
  userSlice.actions;
export default userSlice.reducer;
