import { Box, Grid, Typography } from '@mui/material';
import GuestLayout from '../../components/layout/GuestLayout';
import { Image } from 'mui-image';
import banner from '../../images/logo/Jonfest-Banner-2023-640x160.jpg';
import { JFFoodRegistrationStatusDTO } from '../../models/registrationStatus';

interface IFoodRegistrationDoneProps {
  status: JFFoodRegistrationStatusDTO;
}

export default function FoodRegistrationDone(
  props: IFoodRegistrationDoneProps
) {

  return (
    <GuestLayout>
      <Grid
        container
        rowSpacing={0}
        sx={{ width: '100%', maxWidth: '640px' }}
        columns={{ xs: 12 }}
        justifyContent="flex-start"
      >
        <Grid item xs={12} alignContent={'center'}>
          <Box m={0.5} mb={2}>
            <Image src={banner} duration={0}></Image>
          </Box>
        </Grid>
        <Grid item xs={12} alignContent={'center'} m={0.5}>
          <Typography variant="h3">
            Thank you for ordering food to Jonfest V!<br />            
          </Typography>
          <Typography>
            <br />
            For international visitors who come from countries that have not invented "Swish" we will accept payment when on site.<br />
            For everyone else, your food order is not valid until payment of <b>{props.status.value}</b> sek is received on swish number 073-6759331.<br />
            A confirmation mail will be sent out as soon as we have registered your payment. We need your payment no later than <b>2023-07-16</b>!<br />
            Please state <b>{props.status.code}</b> as message so we know it's you who have paid.<br />
            <br />
            A confirmation mail will be sent out as soon as we have registered
            your payment.
          </Typography>
          <Typography>
            <br />
            <br />
            (An e-mail with this information has been sent to you as well)
            <br />
            <br />
            Best Regards
            <br /> JONFEST CREW
            <br />
            <br />
          </Typography>
        </Grid>
      </Grid>
    </GuestLayout>
  );
}
