import axios, { AxiosRequestConfig } from 'axios';
import { RegistrationStatus } from '../models/enums';
import { IGuestFood, JFGuestFoodDTO, JFGuestFoodSlotCheckInDTO, JFGuestFoodSlotDTO } from '../models/food';
import { JFGuestDTO } from '../models/guest';
import { JFCheckInDTO, JFPaidInfoDTO } from '../models/paidInfo';
import { IUserRegistration } from '../models/userRegistration';
import authHeader from './auth-header';
import { JFFoodSlotDTO } from '../models/foodRegistration';

const API_URL = process.env.REACT_APP_API;

class AdminService {
  getGuests() {
    return axios.get(API_URL + 'admin/GetGuests', this.getConfig());
  }

  getGuest(id: string) {
    return axios.get(API_URL + 'admin/GetGuest?id=' + id, this.getConfig());
  }

  saveGuest(guest: JFGuestDTO) {
    return axios.post(API_URL + 'admin/SaveGuest', guest, this.getConfig());
  }

  postPaid(paidInfo: JFPaidInfoDTO) {
    return axios.post(API_URL + 'admin/Pay', paidInfo, this.getConfig());
  }

  postPaidFood(paidInfo: JFPaidInfoDTO) {
    return axios.post(API_URL + 'admin/PayFood', paidInfo, this.getConfig());
  }

  postCheckInFood(paidInfo: JFGuestFoodSlotCheckInDTO) {
    return axios.post(API_URL + 'admin/CheckInFood', paidInfo, this.getConfig());
  }


  postConfirmed(paidInfo: JFPaidInfoDTO) {
    return axios.post(API_URL + 'admin/Confirm', paidInfo, this.getConfig());
  }

  postCheckIn(checkIn: JFCheckInDTO) {
    return axios.post(API_URL + 'admin/CheckIn', checkIn, this.getConfig());
  }

  getConfig(): AxiosRequestConfig {
    return {
      headers: authHeader(),
    };
  }

  postSendPreInfo() {
    return axios.post(
      API_URL + 'admin/SendPreInfoMail',
      null,
      this.getConfig()
    );
  }

  postTicketSale() {
    return axios.post(
      API_URL + 'admin/SendTicketsMail',
      null,
      this.getConfig()
    );
  }

  postFoodInfo() {
    return axios.post(API_URL + 'admin/SendFoodMail', null, this.getConfig());
  }

  deleteGuest(guest: JFGuestDTO) {
    return axios.post(API_URL + 'admin/DeleteGuest', guest, this.getConfig());
  }

  getGuestFoodConfirmList() {
    return axios.get<JFGuestFoodDTO[]>(
      API_URL + 'admin/GetGuestFoodConfirmList',
      this.getConfig()
    );
  }

  getFoodSlots() {
    return axios.get<JFFoodSlotDTO[]>(
      API_URL + 'admin/GetFoodSlots',
      this.getConfig()
    );

  }

  getGuestFoodList(foodslot : number) {
    return axios.get<JFGuestFoodSlotDTO[]>(
      API_URL + 'admin/getGuestFoodList/' + foodslot.toString(),
      this.getConfig()
    );
  }

  getGuestFood(guestId : string) {
    return axios.get<JFGuestFoodSlotDTO[]>(
      API_URL + 'admin/GetGuestFood/' + guestId,
      this.getConfig()
    );
  }

  saveFood(food : IGuestFood)
  {
    return axios.post(API_URL + 'admin/SaveFood', food, this.getConfig());
  }
  
}

export default new AdminService();
