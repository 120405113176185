import { useEffect, useState } from 'react';
import { FoodTypeEnum, RegistrationStatus } from '../../models/enums';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IFoodRegistration,
  JFFoodSlotDTO,
} from '../../models/foodRegistration';
import guestService from '../../services/guest.service';
import { Controller, useForm } from 'react-hook-form';
import { Image } from 'mui-image';
import GuestLayout from '../../components/layout/GuestLayout';
import banner from '../../images/logo/Jonfest-Banner-2023-640x160.jpg';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import FoodRegistrationClosed from '../../pages/jonfest/FoodRegistrationClosed';
import FoodRegistrationDone from './FoodRegistrationDone';
import { JFFoodRegistrationStatusDTO } from '../../models/registrationStatus';

export default function FoodRegistrationForm() {
  const [isLoadingError, setLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [foodRegistration, setFoodRegistration] = useState<IFoodRegistration>();
  const [registrationOpen, setRegistrationOpen] = useState<RegistrationStatus>(
    RegistrationStatus.Closed
  );
  const [sum, setSum] = useState(0);
  const [foodRegistrationStatus, setFoodRegistrationStatus] =
    useState<JFFoodRegistrationStatusDTO>();

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    guestService
      .checkFoodRegistration(params.code!)
      .then((response) => {
        switch (response.data) {
          case RegistrationStatus.Closed:
            navigate('/foodRegistrationClosed');
            break;
          case RegistrationStatus.NotYetOpened:
            //navigate('/foodRegistrationNotOpen');
            navigate('/foodRegistrationClosed');
            break;
          case RegistrationStatus.Full:
            navigate('/foodRegistrationFull');
            break;
        }
        setRegistrationOpen(response.data);
      })
      .catch((err) => {
        setLoadingError(true);
      });
  }, []);

  useEffect(() => {
    console.log(registrationOpen);
    if (registrationOpen == RegistrationStatus.Ok) {
      guestService
        .getFoodRegistration()
        .then((response) => {
          setFoodRegistration({
            guestGuid: '',
            foodSlots: response.data,
          } as IFoodRegistration);
        })
        .catch((err) => {
          setLoadingError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [registrationOpen]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<IFoodRegistration>();

  const onClick = (foodslot: JFFoodSlotDTO, checked: boolean) => {
    if (checked) setSum(sum - foodslot.price);
    else setSum(sum + foodslot.price);
  };

  const foodSlot = (foodslot: JFFoodSlotDTO, index: number) => {
    return (
      <Box margin={0.5} marginTop={0.5} key={index}>
        <Controller
          name={`foodSlots.${index}.foodSlot`}
          control={control}
          defaultValue={foodslot.foodSlot}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Controller
          rules={{ required: false }}
          control={control}
          name={`foodSlots.${index}.booked`}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#fff',
                    '&.Mui-checked': {
                      color: '#f00',
                    },
                  }}
                  checked={value}
                  onChange={onChange}
                  onClick={() => onClick(foodslot, value)}
                />
              }
              label={foodslot.name}
            />
          )}
        />
        {foodslot.type != FoodTypeEnum.MeatOnly ? (
          <Controller
            rules={{ required: false }}
            control={control}
            name={`foodSlots.${index}.vegan`}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#fff',
                      '&.Mui-checked': {
                        color: '#f00',
                      },
                    }}
                    checked={value}
                    onChange={onChange}
                  />
                }
                label={'Vegan?'}
              />
            )}
          />
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const registerFood = async (data: IFoodRegistration) => {
    try {
      setIsSubmitting(true);
      await guestService
        .postFoodRegistration(data)
        .then((res) => {
          switch (res.data.status) {
            case RegistrationStatus.Failed:
              alert('Your food registration failed!');
              break;
            case RegistrationStatus.Done:
              setIsSubmitting(false);
              setFoodRegistrationStatus(
                res.data as JFFoodRegistrationStatusDTO
              );
              break;
            case RegistrationStatus.Full:
              navigate('/foodRegistrationFull');
              break;
            case RegistrationStatus.Closed:
              navigate('/foodRegistrationClosed');
              break;
            case RegistrationStatus.NotYetOpened:
              navigate('/foodRegistrationClosed');
              break;
          }
        })
        .catch((error) => {
          alert(
            'Something went wrong with your food registration. Please try again. If this error persists please contact Jonfest crew through the facebook event.'
          );
        });
    } catch {
      alert(
        'Something went wrong with your food registration. Please try again. If this error persists please contact Jonfest crew through the facebook event.'
      );
    }
  };

  return (
    <GuestLayout>
      {!isLoading ? (
        <>
          {foodRegistrationStatus == undefined && registrationOpen === RegistrationStatus.Ok ? (
            <Grid
              container
              rowSpacing={0}
              sx={{ width: '100%', maxWidth: '640px' }}
              columns={{ xs: 12 }}
              justifyContent="flex-start"
            >
              <Grid item xs={12} alignContent={'center'}>
                <Box m={0.5} pb={1}>
                  <Image src={banner} duration={0}></Image>
                </Box>
              </Grid>
              <Grid item xs={12} alignContent={'center'}>
                <Typography variant="h3">
                  Matbeställning för Jonfest V - 2023
                  <br />
                </Typography>
                <Typography>
                  Kalasivalianer!
                  <br />
                  Nu, äntligen, har vi ordnat med mat. Våra kockar står redo och
                  glada. Senast Söndagen den 16:e Juli behöver vi din
                  beställning samt betalning (så vi hinner handla allt)!
                  <br />
                  <br />
                  Frukost består av:
                  <br />
                  <i>2st frukost frallor, juice och kaffe</i>
                  <br />
                </Typography>
                <Typography variant="h4">
                  <b>Fredag</b>
                </Typography>
                <Typography>
                  <b>Lunch</b>
                  <br />
                  <i>Italiens pastasallad (salami / veg)</i>
                </Typography>
                <Typography>
                  <b>Middag</b>
                  <br />
                  <i>Lasagne al forno (kött / veg)</i>
                  <br />
                  <br />
                </Typography>
                <Typography variant="h4">
                  <b>Lördag</b>
                </Typography>
                <Typography>
                  <b>Lunch</b>
                  <br />
                  <i>Grekisk couscous sallad (kyckling / veg)</i>
                </Typography>
                <Typography>
                  <b>Middag</b>
                  <br />
                  <i>Fransk potatissallad med (sotad fläskfile / zuccini)</i>
                  <br />
                  <br />
                </Typography>
                <Typography>
                  Frukost 30 SEK, Lunch / Middag 100 SEK. Kryssa i vilka
                  måltider du önskar.
                </Typography>

                <form autoComplete="off" onSubmit={handleSubmit(registerFood)}>
                  <Controller
                    name={`guestGuid`}
                    control={control}
                    defaultValue={params.code}
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                  {foodRegistration?.foodSlots.map((fs, index) => {
                    return foodSlot(fs, index);
                  })}
                  <Box margin={0.5} marginTop={2.5}>
                    {isSubmitting ? (
                      <>
                        <Box paddingLeft={4}>
                          <CircularProgress size={30}></CircularProgress>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography>
                          <b>
                            Totalt: <i>{sum}</i> SEK
                            <br />
                            <br />
                          </b>
                        </Typography>
                        <Button variant="outlined" type="submit">
                          Beställ
                        </Button>
                      </>
                    )}
                  </Box>
                </form>
              </Grid>
            </Grid>
          ) : (
            <>
              {foodRegistrationStatus?.status === RegistrationStatus.Done ? (
                <>
                  <FoodRegistrationDone status={foodRegistrationStatus!} />
                </>
              ) : (
                <>
                  <FoodRegistrationClosed />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Box
          sx={{ maxWidth: '300px', minWidth: '300px' }}
          textAlign="center"
          p={1}
        >
          {isLoadingError ? (
            <>
              <Typography>
                Unable to load Jonfest food registration at this point.
              </Typography>
            </>
          ) : (
            <>
              <CircularProgress></CircularProgress>
              <Typography>Loading...</Typography>
            </>
          )}
        </Box>
      )}
    </GuestLayout>
  );
}
