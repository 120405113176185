import { Box, Grid, Typography } from "@mui/material";
import GuestLayout from "../../components/layout/GuestLayout";
import { Image } from 'mui-image';
import banner from '../../images/logo/Jonfest-Banner-2023-640x160.jpg';
import { useParams } from "react-router-dom";

export default function RegistrationDone() {

  const params = useParams();

  return (
    <GuestLayout>
      <Grid
        container
        rowSpacing={0}
        sx={{ width: '100%', maxWidth: '640px' }}
        columns={{ xs: 12 }}
        justifyContent="flex-start"
      >
        <Grid item xs={12} alignContent={'center'}>
          <Box m={0.5} mb={2}>
            <Image src={banner} duration={0} ></Image>
          </Box>
        </Grid>
        <Grid item xs={12} alignContent={'center'}  m={0.5} >
          <Typography variant='h3'>
            Thank you for registering to Jonfest V - 2023!<br />
          </Typography>
          <Typography>
            <br />
            For international visitors who come from countries that have not invented "Swish" we will accept payment when on site.<br />
            For everyone else, your registration is not valid until payment <b>(666 SEK)</b> is received on swish number <b>073-6759331</b>. <br /><br />
            Use the booking code: <b>{params.code}</b> as message on your payment (if you are paying for more than one, please pay them one at the time with the corresponding booking code)<br /><br />
            A confirmation mail will be sent out as soon as we have registered your payment.<br />
          </Typography>
          <Typography pt={1} pb={1}>            
            <b><i>Without payment your reserved spot will magically disappear in 7 days.</i></b>
          </Typography>
          <Typography>
            Your reserved spot is for you, and you alone.
            You cannot sell it or send someone else in your place. If you cannot make it, contact us and we will sort something out.<br />
            <br />
            (An e-mail with this information has been sent to you as well)<br />
            <br />
            Best Regards<br /> JONFEST CREW<br /><br />
          </Typography>
        </Grid>
      </Grid>
    </GuestLayout>

  );
}
