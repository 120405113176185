export enum RegistrationStatus {
  Failed = 0,
  Ok = 1,
  Full = 2,
  Closed = 3,
  NotYetOpened = 4,
  Done = 5,
}

export enum GuestTypeEnum {
  Normal = 1,
  GuestList = 2,
  BandMember = 3,
  BandOther = 4,
  Crew = 5,
  All = 10,
}

export enum FoodTypeEnum {
  Undefined = 0,
  VeganOption = 1,
  MeatOnly = 2,
}

export enum FoodStatusEnum {
  Undefined = 0,
  Ordered = 1,
  Paid = 2,
  CheckedOut = 3,
}
