import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import authService from "../../services/auth.service";
import { Box, Button, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { InputFormField } from "../fields/InputField";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setUser } from "../../redux/slices/user";
import { c7AuthUser } from "../../models/auth/c7AuthUser";
import {loginTheme} from "../../Theme";

const LoginWidget: FC = (): JSX.Element => {
  const [isLoggingIn, setisLoggingIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {    
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const login = (data: any) => {
    setisLoggingIn(true);
    authService.login({ username: data.username, password: data.password }).then((response) => {            
      if (response.isAuthenticated === false) {
        setisLoggingIn(false);
        toast.error(response.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      } else {
        setisLoggingIn(false);
        dispatch(setUser(response as c7AuthUser));
        navigate('/guestslist');
      }
    })

      .catch((error) => {
        setisLoggingIn(false);
        console.log(error);
      });
  };

  return (
    <>
       <ThemeProvider theme={loginTheme}>
       <CssBaseline></CssBaseline>
      <Box className="login-card">
        <Box margin={0.5}>
          <Typography variant="h2">Login Jonfest Admin</Typography>
        </Box>
        <form autoComplete="off" onSubmit={handleSubmit(login)}>
          <Box margin={0.5} marginTop={1.5}>
            <InputFormField name='username' message='Username is required' control={control} autoComplete="username" label='Username' type='text'/>
          </Box>
          <Box margin={0.5} marginTop={1.1}>
            <InputFormField name='password' message='Password is required' control={control} autoComplete="current-password" label='Password' type='password'/>
          </Box>
          <Box margin={0.5} marginTop={1.1}>
            <Button variant="outlined" type="submit" disabled={isLoggingIn}>
              Login
            </Button>            
          </Box>
        </form>
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
      </ThemeProvider>
    </>
  );
};
export default LoginWidget;