import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import adminService from '../../services/admin.services';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from '@mui/x-data-grid';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { JFPaidInfoDTO } from '../../models/paidInfo';
import Loading from '../widgets/Loading';
import { GuestTypeEnum } from '../../models/enums';
import { Link, useNavigate } from 'react-router-dom';
import { JFGuestFoodDTO, JFGuestFoodSlotDTO } from '../../models/food';

export default function GuestFoodConfirmList() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPostingPay, setIsPostingPay] = useState<number | undefined>();
  const [isLoadingError, setLoadingError] = useState(false);
  const [guests, setGuests] = useState<JFGuestFoodDTO[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    adminService
      .getGuestFoodConfirmList()
      .then((response) => {
        setGuests(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setLoadingError(true);
      });
  }, []);

  const handleOnCellClick = (params: GridCellParams<JFGuestFoodDTO>) => {
    if (params.field === 'name') {
      navigate('/guest/' + params.row.id.toString());
    }

    if (params.field === 'paidFood') {
      var cost = (params.row.foodSlots as JFGuestFoodSlotDTO[]).reduce(
        (sum: number, current: JFGuestFoodSlotDTO) =>
          sum + current.price,
        0
      );
      if(cost > 0)
      {
        setIsPostingPay(params.row.id);
        adminService
          .postPaidFood({
            guestId: params.row.id,
            sendConfirm: true,
            value: !params.row.paidFood,
          } as JFPaidInfoDTO)
          .then((resp) => {
            params.row.paidFood = !params.row.paidFood;
            setIsPostingPay(undefined);
          })
          .catch((error) => {
            alert('Unable to Confirm guest! ' + error.message);
          });
      }
    }
  };

  const columns = useMemo<GridColDef<JFGuestFoodDTO>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Namn',
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <>{params.value}</>;
        },
      },
      {
        field: 'guid',
        headerName: 'Kod',
        flex: 0.3,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <>{params.value?.substring(0, 6)}</>;
        },
      },
      {
        field: 'cost',
        headerName: 'Pris (SEK)',
        flex: 1,
        renderCell: (params: GridRenderCellParams<JFGuestFoodDTO>) => {
          return (
            <>
              {' '}
              {(params.row.foodSlots as JFGuestFoodSlotDTO[]).reduce(
                (sum: number, current: JFGuestFoodSlotDTO) =>
                  sum + current.price,
                0
              )}
            </>
          );
        },
      },
      {
        field: 'paidFood',
        headerName: 'Betalt',
        flex: 0.35,
        renderCell: (params: GridRenderCellParams<boolean>) => {
          if (isPostingPay == params.row.id) {
            return (
              <Box ml={0.5} pt={0.5}>
                <CircularProgress size={16}></CircularProgress>
              </Box>
            );
          } else if (params.row.foodSlots.length == 0) {
            return <Box ml={0.5} pt={0.5}>-</Box>
          } else if (params.value == true) {
            return <CheckBoxOutlinedIcon color={'success'} />;
          } else {
            return <CheckBoxOutlineBlankIcon />;
          }
        },
      },
    ],
    [isPostingPay]
  );

  return (
    <Grid item xs={12}>
      {isLoadingError ? (
        <>
          <Typography>
            Error: Unable to load Jonfest guests at this point!
          </Typography>
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <Loading></Loading>
            </>
          ) : (
            <>
              <Box>
                <Typography>Mat</Typography>
              </Box>
              <div style={{ height: 650, width: '100%' }}>
                <DataGrid
                  disableSelectionOnClick
                  headerHeight={32}
                  rowHeight={24}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  rows={guests}
                  columns={columns}
                  onCellClick={handleOnCellClick}
                  getRowClassName={(params) =>
                    `guest-list--${params.row.guestType}`
                  }
                />
              </div>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
