import { Box, Button, Grid, Typography } from '@mui/material';
import { Console } from 'console';
import { useState } from 'react';
import PageLayout from '../components/layout/PageLayout';
import Loading from '../components/widgets/Loading';
import adminServices from '../services/admin.services';

export default function Admin() {
  return (
    <PageLayout>
      <Grid item xs={6} m={2}>
        <Typography variant="h3">Admin</Typography>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={12}>
        <AdminControl></AdminControl>
      </Grid>
    </PageLayout>
  );
}

export function AdminControl() {
  const [mailSent, setMailSent] = useState<number | undefined>();
  const [isMailing, setIsMailing] = useState(false);

  const sendPreInfo = () => {
    setIsMailing(true);
    adminServices.postSendPreInfo().then((resp) => {
      console.log(resp.data);
      setMailSent(resp.data);
      setIsMailing(false);
    });
  };

  const sendTicketInfo = () => {
    setIsMailing(true);
    adminServices.postTicketSale().then((resp) => {
      console.log(resp.data);
      setMailSent(resp.data);
      setIsMailing(false);
    });
  };

  const sendFoodInfo = () => {
    setIsMailing(true);
    adminServices.postFoodInfo().then((resp) => {
      console.log(resp.data);
      setMailSent(resp.data);
      setIsMailing(false);
    });
  };

  return (
    <Box p={2} border={1} ml={2} width={250}>
      {isMailing ? (
        <>
          <Loading></Loading>
        </>
      ) : (
        <>
          <Button variant="contained" onClick={sendPreInfo}>
            Skicka för-information
          </Button>
          <br />
          <br />
          <Button variant="contained" onClick={sendTicketInfo}>
            Skicka Biljettmail
          </Button>
          <br />
          <br />
          <Button variant="contained" onClick={sendFoodInfo}>
            Skicka Matbeställningsmail
          </Button>
          <br />
          <br />
          <Typography>
            {mailSent ? <>Skickade mail: {mailSent}</> : <></>}
          </Typography>
        </>
      )}
    </Box>
  );
}
