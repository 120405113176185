import { useEffect, useMemo, useState } from "react"
import { JFGuestDTO } from "../../models/guest"
import adminService from '../../services/admin.services';
import { IGuestFood, JFGuestFoodSlotDTO } from "../../models/food";
import Loading from "../widgets/Loading";
import { Controller, useForm } from "react-hook-form";
import { JFFoodSlotDTO } from "../../models/foodRegistration";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Typography } from "@mui/material";
import { FoodStatusEnum } from "../../models/enums";

export interface GuestFoodProps
{
    guest : JFGuestDTO
}

export default function GuestFood(props : GuestFoodProps) {
    const [food, setFood] = useState<JFGuestFoodSlotDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [foodRegistration, setFoodRegistration] = useState<IGuestFood>();
    const [savedMessage, setSavedMessage] = useState<string | undefined>();
    
    const {
        handleSubmit,
        reset,
        formState: { errors },
        control,
      } = useForm<IGuestFood>({
        defaultValues: useMemo(() => {
          return foodRegistration
        }, [foodRegistration]),
      });
    
    useEffect(() => 
    {
        adminService.getGuestFood(props.guest.guid).then((resp) => 
        {            
            setFood(resp.data);
            setIsLoading(false);
        }).catch((err) => {
            alert("FEL: Kan inte ladda matbeställning");
          });

    }, [])

    useEffect(() => {
        setFoodRegistration(
            {
                guestGuid : props.guest.guid,
                foodSlots: food
            } as IGuestFood            
        );
    }, [food])

    
  const registerFood = async (data : IGuestFood) => {
      setSavedMessage(undefined);
      setIsSubmitting(true);
      adminService.saveFood(data).then((res) => {
        setSavedMessage('Saved!');
      })
      .catch((err) => {
        setSavedMessage('Error!');
        alert('Unable to save food');
      })
      .finally(() => {
        setIsSubmitting(false);
      });

     
  };

  const foodSlot = (foodslot: JFGuestFoodSlotDTO, index: number) => {
    
    return (
      <Box margin={0.5} marginTop={0.5} key={index}>
        <Controller
          name={`foodSlots.${index}.foodSlot`}
          control={control}
          defaultValue={foodslot.foodSlot}
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <Controller
          rules={{ required: false }}
          control={control}
          name={`foodSlots.${index}.booked`}
          defaultValue={foodslot.booked}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Checkbox
                
                  checked={value}
                  onChange={onChange}
                />
              }
              label={foodslot.name}
            />
          )}
        />
        <Controller
        rules={{ required: false }}
        control={control}
        name={`foodSlots.${index}.vegan`}
        defaultValue={foodslot.vegan}
        render={({ field: { onChange, value } }) => (
            <FormControlLabel
            control={
                <Checkbox
              
                checked={value}
                onChange={onChange}
                />
            }
            label={'Vegan?'}
            />
        )}
        />
     
      </Box>
    );
  };
  
    return <>
       {isLoading ? (
            <>
              <Loading></Loading>
            </>
          ) : (
            <>  <form autoComplete="off" onSubmit={handleSubmit(registerFood)}>
             <Controller
          name={`guestGuid`}
          control={control}
          defaultValue={foodRegistration?.guestGuid}
          render={({ field }) => <input type="hidden" {...field} />}
        />
            {foodRegistration?.foodSlots.map((fs, index) => {
              return foodSlot(fs, index);
            })}
            <Box margin={0.5} marginTop={2.5}>
              {isSubmitting ? (
                <>
                  <Box paddingLeft={4}>
                    <CircularProgress size={30}></CircularProgress>
                  </Box>
                </>
              ) : (
                <>
                  <Typography>
                   
                  </Typography>
                  <Button variant="outlined" type="submit">
                    Spara Mat
                  </Button>
                  <Box margin={0.5} marginTop={1.0}>
                            <Typography>{savedMessage}</Typography>
                          </Box>

                </>
              )}
            </Box>
          </form></> ) }
    </>
}