import { Grid, Typography } from '@mui/material';
import PageLayout from '../../components/layout/PageLayout';
import GuestFoodCheckOffList from '../../components/admin/GuestFoodCheckOffList';

export default function FoodCheckOff() {
  return (
    <PageLayout>
      <Grid item xs={12}>
        <GuestFoodCheckOffList></GuestFoodCheckOffList>
      </Grid>
    </PageLayout>
  );
}
