import { Box, Grid, Typography } from "@mui/material";
import GuestLayout from "../../components/layout/GuestLayout";
import { Image } from 'mui-image';
import banner from '../../images/logo/Jonfest-Banner-2023-640x160.jpg';

export default function RegisterFull() {
  return (
    <GuestLayout>
      <Grid
        container
        rowSpacing={0}
        sx={{ width: '100%', maxWidth: '640px' }}
        columns={{ xs: 12 }}
        justifyContent="flex-start"
      >
        <Grid item xs={12} alignContent={'center'}>
          <Box m={0.5} mb={2}>
            <Image src={banner} duration={0} ></Image>
          </Box>
        </Grid>
        <Grid item xs={12} alignContent={'center'}  m={0.5} >
          <Typography variant='h3'>
            Sadly all tickets are already reserved!<br />
          </Typography>
          <Typography>
            <br />
            Your reservation has been added to the queue. If any tickets become available we are sending out e-mails to the next person in the queue, so don't give up just yet!
          </Typography>
          <Typography>
            <br />
            Best Regards<br /> JONFEST CREW<br /><br />
          </Typography>
        </Grid>
      </Grid>
    </GuestLayout>

  );
}
