import React from "react";
import { Navigate, RouteProps, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setUser } from "../../redux/slices/user";
import authService from "../../services/auth.service";

export function PrivateRoute({ children }: RouteProps): JSX.Element {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  var authed : boolean = false;

  if(user && user.isAuthenticated)
  {
    authed = true;
  }
  else
  {
    var authUser = authService.getLoggedIn();
    if(authUser.isAuthenticated)
    {
      dispatch(setUser(authUser));
      authed = true;
    }  
  }

  return <>{authed ? children : <Navigate to="/login" />}</>;
};

export default PrivateRoute;