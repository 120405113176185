import { Navigate } from 'react-router-dom';

const Front = () => {
  return <FrontComponent></FrontComponent>;
};

const FrontComponent = () => {

  return (
    <Navigate to="/register" />
  );
};

export default Front;
