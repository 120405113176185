import { Grid, Typography } from '@mui/material';
import GuestConfirmList from '../../components/admin/GuestConfirmList';
import PageLayout from '../../components/layout/PageLayout';

export default function GuestListConfirm() {
  return (
    <PageLayout>
      <Grid item xs={12}>
        <GuestConfirmList></GuestConfirmList>
      </Grid>
    </PageLayout>
  );
}
