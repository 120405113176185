import axios from 'axios';
import {
  JFFoodRegistrationStatusDTO,
  JFRegistrationStatusDTO,
} from '../models/registrationStatus';
import { IUserRegistration } from '../models/userRegistration';
import { IFoodRegistration, JFFoodSlotDTO } from '../models/foodRegistration';

const API_URL = process.env.REACT_APP_API;

class GuestService {
  checkRegistration() {
    return axios.get(API_URL + 'jonfest/checkRegistration');
  }

  getCountries() {
    return axios.get(API_URL + 'jonfest/getCountries');
  }

  postRegistration(data: IUserRegistration) {
    return axios.post<JFRegistrationStatusDTO>(
      API_URL + 'jonfest/RegisterGuest',
      data
    );
  }

  checkFoodRegistration(guestId: string) {
    return axios.get(API_URL + 'jonfest/checkFoodRegistration/' + guestId);
  }

  getFoodRegistration() {
    return axios.get<JFFoodSlotDTO[]>(API_URL + 'jonfest/getFoodRegistration');
  }

  postFoodRegistration(data: IFoodRegistration) {
    return axios.post<JFFoodRegistrationStatusDTO>(
      API_URL + 'jonfest/registerFood',
      data
    );
  }
}

export default new GuestService();
