import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GuestTypeEnum } from '../../models/enums';
import { JFGuestDTO } from '../../models/guest';
import adminService from '../../services/admin.services';
import { InputFormField } from '../fields/InputField';
import Loading from '../widgets/Loading';
import { enumKeys } from '../../util/common';
import GuestFood from './GuestFood';

export default function GuestForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingError, setLoadingError] = useState(false);
  const [guest, setGuest] = useState<JFGuestDTO>();
  const [savedMessage, setSavedMessage] = useState<string | undefined>();

  const [food, setFood] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<JFGuestDTO>({
    defaultValues: useMemo(() => {
      return guest;
    }, [guest]),
  });

  useEffect(() => {
    if(params.id != undefined)
    {
    adminService
      .getGuest(params.id!)
      .then((response) => {
        setGuest(response.data as JFGuestDTO);
        setIsLoading(false);
      })
      .catch((err) => {
        setLoadingError(true);
      });
    }
    else
    {
      setGuest({
        country : 'Sweden',        
      } as JFGuestDTO);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    reset(guest);
  }, [guest]);

  const saveGuest = async (data: JFGuestDTO) => {
    console.log("Guest!");
    setIsSubmitting(true);
    setSavedMessage(undefined);
    if(data.sendfoodmail == true)
    {
      data.flag = "SendFoodMail";
    }
    adminService
      .saveGuest(data)
      .then((res) => {
        setSavedMessage('Saved!');
      })
      .catch((err) => {
        alert('Unable to save guest');
        setSavedMessage('Error!');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const clickDelete = (data: JFGuestDTO) => {
    if (window.confirm('Are you sure you want to delete this guest?')) {
      setIsLoading(true);
      adminService
        .deleteGuest(data)
        .then((resp) => {
          setIsLoading(false);
          navigate(-1);
        })
        .catch((err) => {
          alert(err.message);
          setIsLoading(false);
        });
    }
  };

  const showFood = ()  => {
    setFood(true);
  }

  return (
    <Grid item xs={12}>
      {isLoadingError ? (
        <>
          <Typography>Error: Unable to load guest at this point!</Typography>
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <Loading></Loading>
            </>
          ) : (
            <>
              <form autoComplete="off" onSubmit={handleSubmit(saveGuest)}>
                <Box margin={0.5} marginTop={1.5}>
                  <InputFormField
                    name="name"
                    message="Name is required"
                    control={control}
                    label="Name"
                    type="text"
                  />
                </Box>
                <Box margin={0.5} marginTop={1.5}>
                  <InputFormField
                    name="birthday"
                    message="Birthday is required"
                    control={control}
                    label="Birthday (yymmdd)"
                    type="text"
                  />
                </Box>
                <Box margin={0.5} marginTop={1.5}>
                  <InputFormField
                    name="email"
                    message="Email is required"
                    control={control}
                    label="Email"
                    type="text"
                  />
                </Box>
                <Box margin={0.5} marginTop={1.5}>
                  <InputFormField
                    name="phone"
                    message="Mobilephone number is required"
                    control={control}
                    label="Mobilephone number"
                    type="text"
                  />
                </Box>
                <Box margin={0.5} marginTop={-1.0}>
                  <Controller
                    rules={{ required: false }}
                    control={control}
                    name={'tent'}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox checked={value} onChange={onChange} />
                        }
                        label={'Sleeping in tent?'}
                      />
                    )}
                  />
                </Box>
                <Box margin={0.5} marginTop={1.5}>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name={'country'}
                    defaultValue={'Sweden'}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="country-select-label"
                          label="Country"
                          value={value}
                          onChange={onChange}
                          size={'small'}
                        >
                          <MenuItem value={'Sweden'}>Sweden</MenuItem>
                          <MenuItem value={'Denmark'}>Denmark</MenuItem>
                          <MenuItem value={'Norway'}>Norway</MenuItem>
                          <MenuItem value={'England'}>England</MenuItem>
                          <MenuItem value={'USA'}>USA</MenuItem>
                          <MenuItem value={'Finland'}>Finland</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box margin={0.5} marginTop={3.0}>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name={'guestType'}
                    defaultValue={GuestTypeEnum.Normal}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <InputLabel id="guesttype-select-label">
                          Guest type
                        </InputLabel>
                        <Select
                          labelId="guesttype-select-label"
                          label="Guest type"
                          value={value}
                          onChange={onChange}
                          size={'small'}
                        >
                          {Object.entries(GuestTypeEnum)
                            .filter(([v, n]) => isNaN(Number(v)))
                            .map(([word, value]) => {
                              return <MenuItem value={value}>{word}</MenuItem>;
                            })}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Box>
                {guest?.id == undefined ? <> <Box margin={0.5} marginTop={-1.0}>
                  <Controller
                    rules={{ required: false }}
                    control={control}
                    name={'sendfoodmail'}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox checked={value} onChange={onChange} />
                        }
                        label={'Skicka matbeställningsmail?'}
                      />
                    )}
                  />
                </Box></> : <>              
                  </>}
                <Box margin={0.5} marginTop={3.0}>
                  <Typography>{guest?.guid}</Typography>
                </Box>
                <Box margin={0.5} marginTop={2.5}>
                  {isSubmitting ? (
                    <>
                      <Box paddingLeft={4}>
                        <CircularProgress size={30}></CircularProgress>
                      </Box>
                    </>
                  ) : (
                    <Grid container width={'100%'}>
                      <Grid container flex={2.0}>
                        <Grid item>
                          <Button variant="outlined" type="submit">
                            Spara gäst
                          </Button>
                        </Grid>
                        <Grid item>
                          <Box margin={0.5} marginTop={1.0}>
                            <Typography>{savedMessage}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container flex={1.0} justifyContent={'flex-end'}>
                        <Grid item marginLeft={1.0} justifyContent={'flex-end'}>
                          <Button
                            variant="outlined"
                            type="button"
                            size="small"
                            onClick={() => clickDelete(guest!)}
                          >
                            Ta bort!
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </form>
              {guest?.id != undefined ? 
                <Box margin={1.0} >
                  {food ? <>
                    <GuestFood guest={guest}></GuestFood>
                  </> :
                  <a href='#' onClick={() => showFood()}>Visa mat &gt;&gt;&gt; </a>
                      }
                  </Box>
                  : <></>
                    }
            </>
          )}
        </>
      )}
    </Grid>
  );
}
