import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import se_flag from '../../images/flags/se.png';
import en_flag from '../../images/flags/en.png';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';

var logo = require('../../images/logo/JonFest_h48.png');

const TopBar = () => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const languages = [
    { flag: se_flag, lang: 'se', desc: 'Svenska' },
    { flag: en_flag, lang: 'en', desc: 'English' },
  ];

    const Logout = () => {
    console.log("Logout");
    navigate('/logout');    
  }

  return (
    <AppBar
      position="fixed"
      color="primary"
      
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      elevation={0}
    >
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Toolbar disableGutters variant="dense">
          <Box sx={{ flexGrow: 0 }} marginLeft={0.5}>
            <img src={logo} alt="JonFest" style={{ height: 48 }} />
          </Box>
          <Box sx={{ flexGrow: 1 }} alignContent={'center'}>         
            <Typography textAlign="center" variant='body2'>{user.username}</Typography>
            </Box>
          <Box sx={{ flexGrow: 0 }}><Button variant='outlined' onClick={() => {
            Logout();
          }}>Logga ut</Button></Box>

        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default TopBar;
