import { Grid, Typography } from '@mui/material';
import GuestForm from '../../components/admin/GuestForm';
import PageLayout from '../../components/layout/PageLayout';

export default function Guest() {
  return (
    <PageLayout>
      <Grid
        container
        rowSpacing={0}
        sx={{ width: '100%', maxWidth: '48em' }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="flex-start"
      >
        <GuestForm></GuestForm>
      </Grid>
    </PageLayout>
  );
}
