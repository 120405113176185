import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Image } from 'mui-image';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import guestService from '../../services/guest.service';
import { InputFormField } from '../fields/InputField';
import GuestLayout from '../layout/GuestLayout';

import banner from '../../images/logo/Jonfest-Banner-2023-640x160.jpg';
import { IUserRegistration } from '../../models/userRegistration';
import { RegistrationStatus } from '../../models/enums';
import { useNavigate } from 'react-router-dom';
import RegistrationClosed from './RegistrationClosed';
import { JFRegistrationStatusDTO } from '../../models/registrationStatus';


export default function RegistrationForm() {

    const [isLoadingError, setLoadingError] = useState(false);
    //const [isSubmitting, setIsSubmitting] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [country, setCountry] = useState('0');
    const [registrationOpen, setRegistrationOpen] = useState<RegistrationStatus>(RegistrationStatus.Closed);

    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent) => {
        setCountry(event.target.value as string);
    };

    const {
        handleSubmit,

        formState: { errors, isSubmitting },
        control,
    } = useForm<IUserRegistration>();

    const register = async (data: IUserRegistration) => {
        try {
            await guestService.postRegistration(data).then((res) => {
                switch (res.data.status) {
                    case RegistrationStatus.Failed:
                        alert('Your registration has failed!');
                        break;
                    case RegistrationStatus.Ok:
                        navigate('/registrationDone/' + res.data.code);
                        break;
                    case RegistrationStatus.Full:
                        navigate('/registrationFull');
                        break;
                    case RegistrationStatus.Closed:
                        navigate('/registrationClosed');
                        break;
                    case RegistrationStatus.NotYetOpened:
                        navigate('/registrationNotOpen');
                        break;                        
                }
            }).catch((error) => {
                alert('Something went wrong with your registration. Please try again. If this error persists please contact Jonfest crew through the facebook event.');
            });
        }
        catch
        {
            alert('Something went wrong with your registration. Please try again. If this error persists please contact Jonfest crew through the facebook event.');
        }
    };

    useEffect(() => {
        guestService.checkRegistration().then((response) => {
            switch (response.data) {                           
                case RegistrationStatus.Closed:
                    navigate('/registrationClosed');
                    break;
                case RegistrationStatus.NotYetOpened:
                    navigate('/registrationNotOpen');
                    break;                        
            }
            setRegistrationOpen(response.data);
            setIsLoading(false);
        }).catch((err) => {
            setLoadingError(true);
        });
    }, []);

    return (
        <GuestLayout>
            {!isLoading ? (
                <>
                    {registrationOpen === RegistrationStatus.Ok || registrationOpen === RegistrationStatus.Full ? (
                        <Grid
                            container
                            rowSpacing={0}
                            sx={{ width: '100%', maxWidth: '640px' }}
                            columns={{ xs: 12 }}
                            justifyContent="flex-start"
                        >
                            <Grid item xs={12} alignContent={'center'}>
                                <Box m={0.5} pb={1}>
                                    <Image src={banner} duration={0} ></Image>
                                </Box>
                            </Grid>
                            <Grid item xs={12} alignContent={'center'} m={0.5} >
                                {registrationOpen === RegistrationStatus.Full ? (<>
                                    <Typography variant='h4'>
                                        Sadly all tickets are already reserved!<br />
                                    </Typography>
                                    <Typography><b>But you can register below to be placed in a queue and if there will be any tickets available we will send out e-mails to persons in the queue.</b></Typography>
                                </>) : (<>
                                    <Typography variant='h3'>
                                        Registration for the best little Kalasival ever!<br />
                                    </Typography>
                                </>)}
                                <Typography>
                                    <br />
                                    Jonfest V will be taking place 20-22 of July 2023 at the usual spot at Lindholmen (Kolja 448 at google maps).
                                    We open on thursday already even though it will mostly be building, fixing, plugging everything in and so on, but there will be music and drinks. And people!<br />
                                    <br />
                                    Bands: Yes, this is true. We will have music this year as well. To name a few:<br />
                                    Xenturion Prime<br />
                                    Sekkret Tekknik<br />
                                    Thermostatic<br />
                                    Stockholm Wrecking Crew<br />

                                    <br />
                                    DJ’s: YES! There will be daytime entertainment, evening dancefloors, podcasts, radio shows. But details are to follow.<br />
                                    <br />
                                    Beach: Yes. There is indeed a beach and a lake.<br />
                                    <br />
                                    BBQ: Yes, there are several spots just made for a good barbie. The only caveat is if the government implements a restriction in fire due to extreme draught.<br />
                                    <br />
                                    Sauna: Yes, there is, from morning until 22.00. With the same caveat as above.<br />
                                    <br />
                                    Food: We will have prepared food by a proper chef. Registration for this has already been sent, so if you want to order food, please contact us.<br />
                                    <br />
                                    And all of this for <b>666</b> Swedish kronor? Hell of a party!<br />
                                    <br />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} alignContent={'center'}>
                                <form autoComplete="off" onSubmit={handleSubmit(register)}>
                                    <Box margin={0.5} marginTop={1.5}>
                                        <InputFormField name='name' message='Name is required' control={control} label='Name' type='text' />
                                    </Box>
                                    <Box margin={0.5} marginTop={1.5}>
                                        <InputFormField name='birthday' message='Birthday is required' control={control} label='Birthday (yymmdd)' type='text' />
                                    </Box>
                                    <Box margin={0.5} marginTop={1.5}>
                                        <InputFormField name='email' message='Email is required' control={control} label='Email' type='text' />
                                    </Box>
                                    <Box margin={0.5} marginTop={1.5}>
                                        <InputFormField name='phone' message='Mobilephone number is required' control={control} label='Mobilephone number' type='text' />
                                    </Box>
                                    <Box margin={0.5} marginTop={0.5}>
                                        <Controller
                                            rules={{ required: false }}
                                            control={control}
                                            name={'tent'}
                                            defaultValue={true}
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox sx={{
                                                            color: '#fff',
                                                            '&.Mui-checked': {
                                                                color: '#f00',
                                                            }
                                                        }}
                                                            checked={value}
                                                            onChange={onChange} />
                                                    }
                                                    label={
                                                        "I'm gonna sleep in a tent!"
                                                    } />
                                            )}
                                        />
                                    </Box>
                                    <Box margin={0.5} marginTop={1.5}>
                                        <Controller
                                            rules={{ required: true }}
                                            control={control}
                                            name={'country'}
                                            defaultValue={'Sweden'}
                                            render={({ field: { onChange, value } }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="country-select-label">Country</InputLabel>
                                                    <Select
                                                        labelId="country-select-label"
                                                        label="Country"
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        <MenuItem value={'Sweden'} defaultChecked>Sweden</MenuItem>
                                                        <MenuItem value={'Denmark'}>Denmark</MenuItem>
                                                        <MenuItem value={'Norway'}>Norway</MenuItem>
                                                        <MenuItem value={'England'}>England</MenuItem>
                                                        <MenuItem value={'USA'}>USA</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                    <Box margin={0.5} marginTop={2.5}>
                                        {isSubmitting ? (<>
                                            <Box paddingLeft={4}>
                                                <CircularProgress size={30}></CircularProgress>
                                            </Box>
                                        </>) : (
                                            <Button variant="outlined" type="submit" >
                                                {registrationOpen === RegistrationStatus.Full ? (<>Place me in the queue</>) : (<>Register</>)}
                                            </Button>
                                        )}
                                    </Box>
                                </form>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <RegistrationClosed />
                        </>
                    )}
                </>
            ) : (
                <Box sx={{ maxWidth: '300px', minWidth: '300px' }} textAlign='center' p={1}>
                    {isLoadingError ? (<>
                        <Typography>Unable to load Jonfest registration at this point.</Typography>
                    </>) : (
                        <>
                            <CircularProgress></CircularProgress>
                            <Typography>Loading...</Typography>
                        </>
                    )}
                </Box>
            )}
        </GuestLayout>
    );
}
