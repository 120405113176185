import { FC, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logoutUser } from "../../redux/slices/user";
import authService from "../../services/auth.service";

const LogoutWidget: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
  
    dispatch(logoutUser());
    authService.logout();

    return (
      <Navigate to="/login" />
    );
}

export default LogoutWidget;