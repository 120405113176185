import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: string = '';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation: (state, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;
