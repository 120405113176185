import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export const InputFormField = (prop: any) => {
  return (
    <>
      <Controller
        name={prop.name}
        control={prop.control}
        rules={{ required: true }}
        defaultValue={prop.value}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              label={prop.label}
              fullWidth
              error={error !== undefined}
              helperText={error ? prop.message : ' '}
              InputProps={{ style: { height: 29 } }}
              sx={{ '.MuiFormLabel-root[data-shrink=false]': { top: -13 } }}
              type={prop.type}
              autoComplete={prop.autoComplete}
            />
          </FormControl>
        )}
      />
    </>
  );
};
